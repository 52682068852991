@import "../functions", "../mixins";

.loader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;

    &_blue {
        background-color: #31659F;
    }

    &_dark {
        background-color: rgba(0, 0, 0, 0.48);
    }

    &__image {
        width: 214px;
        animation: scale 2s ease infinite;
        will-change: transform;

        @media (max-width:em(768)) {
            width: 100px;
        }
    }
}

@keyframes scale {
    0% {transform: scaleY(1);}
    50% {transform: scaleY(-1);}
    100% {transform: scaleY(1);}
}