@import "../functions", "../mixins";

.modal {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    background-image: url(../../images/contact-form/form-bgr.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 10000;
    padding: 0 16px;
    animation: open-modal 0.3s ease-out forwards;
    opacity: 0;
    will-change: opacity;
    backface-visibility: hidden;
    transform: translateZ(1px);

    &__hide {
        animation: close-modal 0.3s ease-out;
    }

    .body {
        display: grid;
        place-content: center;
        place-items: center;
        position: relative;
        grid-auto-rows: min-content;
        position: relative;
        height: 100%;
        padding: 80px 0;
        max-width: rem(1140);
        margin: 0 auto;
    }

    .title {
        font-family: var(--oswald);
        font-style: normal;
        font-weight: 600;
        font-size: 3.4375rem;
        line-height: 118%;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: var(--white);
        margin-bottom: 24px;

        @media (max-width:em(768)) {
            font-size: 2.375rem;
            margin-bottom: 13px;
        }

        span {
            position: relative;

            &::before {
                @include pseudo($content: 'Thanks!');
                bottom: 100%;
                left: 0;
                font-style: italic;
                font-weight: bold;
                font-size: 2rem;
                line-height: 156%;
                text-transform: uppercase;
                color: var(--orange);

                @media (max-width:em(768)) {
                    font-size: 1.5rem;
                }
            }
        }
    }

    .subtitle {
        font-family: var(--oswald);
        font-style: normal;
        font-weight: 300;
        font-size: 1.75;
        line-height: 146%;
        text-align: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: var(--white);
        margin-bottom: 45px;

        @media (max-width:em(768)) {
            font-size: 1.375rem;
        }
    }

    .close {
        font-family: var(--oswald);
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 150%;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: var(--white);
        background-image: url(../../images/close-white.svg);
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 14px;
        padding-left: 30px;
        position: absolute;
        top: 53px;
        right: 0;

        @media (max-width:em(768)) {
            font-size: 0.875rem;
            background-size: 12px;
            padding-left: 23px;
            top: 27px;
        }
    }
}

@keyframes open-modal {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes close-modal {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}