@import '../functions', '../mixins';

.button {
  padding: 20px 20px 20px 50px;
  max-width: 420px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;
  font-family: var(--oswald);
  font-style: normal;
  font-weight: bold;
  font-size: rem(18);
  line-height: percent(27, 18);
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  background-color: transparent;
  color: var(--blue);
  border-radius: var(--btn-b-radius);
  width: 100%;
  transition: all 0.3s;
  transform: translateZ(0);
  backface-visibility: hidden;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.5);

  &::before {
    @include pseudo();
    width: 100%;
    height: 100%;
    background: linear-gradient(320.66deg, var(--blue) 14.75%, #4884c7 84.81%);
    filter: blur(1px);
    opacity: 0;
    left: 0;
    top: 0;
    z-index: -1;
    transition: all 0.3s;
  }

  &:disabled {
    cursor: default;
  }

  svg {
    transform: rotate(-90deg);

    * {
      transition: all 0.3s;
      fill: var(--blue);
    }
  }

  &:hover {
    color: var(--white);

    &::before {
      opacity: 1;
      box-shadow: inset 0 0 10px 3px rgba(255, 255, 255, 0.15);
    }

    svg * {
      fill: var(--white);
    }
  }

  &_transparent {
    box-shadow: none;
    z-index: 1;
    position: static;
    margin: 0 auto;
    justify-content: center;
    padding: 20px;
    font-size: rem(12);
    gap: 0px;

    @media (max-width: 48em) {
      font-size: rem(14);
      gap: 2px;
      padding-bottom: 9px;
    }

    svg {
      transform: rotate(0deg);
      width: 18px;
    }

    &::before {
      content: none;
    }

    &:hover {
      color: var(--light-blue);

      &::before {
        content: none;
      }

      svg * {
        fill: var(--light-blue);
      }
    }
  }

  &_dark {
    box-shadow: inset 0 0 0 3px #dde3ed;
  }

  &_white {
    svg * {
      fill: var(--white);
    }

    color: var(--white);

    box-shadow: inset 0 0 0 3px var(--white);

    &:hover {
      color: var(--blue);

      &::before {
        background: var(--white);
      }

      svg * {
        fill: var(--blue);
      }
    }
  }
}
