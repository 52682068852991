@import "./root", "./functions", "./mixins";

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

button {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}

a {
    text-decoration: none;
}

html,
body,
#root,
.App {
    height: 100%;
}

body {
    font-family: var(--roboto);
    font-size: rem(16);
    line-height: percent(19, 16);
    color: var(--grey);
    min-width: em(320);
    background-color: var(--white);
    -webkit-font-smoothing: antialiased;
    overflow-anchor: none;

    @media (max-width:em(992)) {
        &.menu-open {
            overflow: hidden;
        }
    }
}

.app {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.formio-component-form {
    width: 600px;

    @media (max-width: em(768)) {
        width: 100%;
    }
}

.formio-form {
    width: 600px;

    @media (max-width: em(768)) {
        width: 100%;
    }
}

.formio-component-email,
.formio-component-password,
.formio-component-fullName,
.formio-component-phoneNumber {
    margin-bottom: 35px;
}

.formio-component-email input,
.formio-component-password input,
.formio-component-fullName input,
.formio-component-phoneNumber input {
    border: 2px solid transparent;
    background: var(--white);
    border-radius: var(--btn-b-radius);
    padding: 20px 30px;
    width: 100%;
    font-family: var(--roboto);
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 118.75%;
    color: #0F3250;
    border: none;
    outline: none;
    box-shadow: inset 0 0 0 2px #dde3ed;


    &::placeholder {
        font-family: var(--roboto);
        font-style: normal;
        font-weight: normal;
        font-size: rem(16);
        line-height: percent(19, 16);
        color: #0F3250;
        opacity: 0.45;
        transition: all 0.3s;
        font-weight: 400;
    }

    &:focus {
        box-shadow: inset 0 0 0 3px #DDE3ED;
        &::placeholder {
            opacity: 0;
        }
    }
}

.formio-component-button button {
    padding: 20px;
    max-width: 263px;
    height: 71px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 5px;
    font-family: var(--oswald);
    font-style: normal;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    background-color: transparent;
    color: var(--blue);
    border-radius: var(--btn-b-radius);
    width: 100%;
    transition: all 0.3s;
    transform: translateZ(0);
    backface-visibility: hidden;
    position: relative;
    overflow: hidden;
    box-shadow: inset 0 0 0 3px #dde3ed;

    &:hover{
        color: var(--white);
        background-color: var(--blue);
        box-shadow: none
    }
}
