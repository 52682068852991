:root {
    --oswald: 'Oswald', sans-serif;
    --roboto: 'Roboto', sans-serif;

    --muted-blue: rgba(15, 50, 80, 0.45);
    --dark-blue: #204F77;
    --light-blue:#4482C6;
    --blue: #31659F;
    --white: #FFF;
    --grey: #687F98;
    --light-grey: #e5e5e5;
    --green: #4C8A3F;
    --light: #DBEEF3;
    --orange: #F6901E;
    --pink: #D66463;
    --purple: #7C3677;
    --red: #E54F24;

    --card-b-radius: 20px;
    --btn-b-radius: 50px;
}